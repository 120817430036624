import React, { ComponentProps, ReactNode, useReducer } from 'react'

import {
  Button,
  Checkbox,
  Inline,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Text,
  Time
} from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

type Column = 'saapunut' | 'maaraaika'

type State = {
  sortDirection: ComponentProps<typeof TableHeaderCell>['sortDirection']
  sortColumn: Column
}

type Action = { type: 'sort'; payload: Column }

const initialState: State = {
  sortDirection: 'ascending',
  sortColumn: 'saapunut'
}

const sortReducer = (state: State, action: Action): State =>
  state.sortColumn === action.payload
    ? {
        ...state,
        sortDirection:
          state.sortDirection === 'ascending' ? 'descending' : 'ascending'
      }
    : {
        ...state,
        sortColumn: action.payload
      }

const TableHeaderExample = () => {
  const [state, dispatch] = useReducer(sortReducer, initialState)

  const onTableHeaderClick = (column: Column) =>
    dispatch({ type: 'sort', payload: column })

  return (
    <TableHeader>
      <TableRow>
        <TableHeaderCell scope="col">
          <Checkbox
            ariaLabel="Valitse kaikki"
            id="valitse-kaikki"
          />
        </TableHeaderCell>
        <TableHeaderCell scope="col">Aihe</TableHeaderCell>
        <TableHeaderCell scope="col">Tarkenne</TableHeaderCell>
        <TableHeaderCell scope="col">Tila</TableHeaderCell>
        <TableHeaderCell scope="col">Asiakas</TableHeaderCell>
        <TableHeaderCell scope="col">Palvelutarve</TableHeaderCell>
        <TableHeaderCell
          scope="col"
          onClick={() => onTableHeaderClick('saapunut')}
          sortDirection={
            state.sortColumn === 'saapunut' ? state.sortDirection : undefined
          }
        >
          Saapunut
        </TableHeaderCell>
        <TableHeaderCell
          scope="col"
          onClick={() => onTableHeaderClick('maaraaika')}
          sortDirection={
            state.sortColumn === 'maaraaika' ? state.sortDirection : undefined
          }
        >
          Määräaika
        </TableHeaderCell>
        <TableHeaderCell
          scope="col"
          style={{ width: '1%' }}
        >
          Toiminnot
        </TableHeaderCell>
      </TableRow>
    </TableHeader>
  )
}

interface TableBodyExampleProps {
  isSmall?: boolean
}

const TableBodyExample = ({ isSmall }: TableBodyExampleProps = {}) => {
  const renderText = (text: ReactNode) =>
    isSmall ? <Text size="sm">{text}</Text> : text

  return (
    <TableBody>
      <TableRow>
        <TableCell>
          <Checkbox
            ariaLabel="Valitse: Työnhaun aloitus"
            id="tyonhaun-aloitus"
          />
        </TableCell>
        <TableCell>
          {renderText(<Link as={LinkMock}>Työnhaun aloitus</Link>)}
        </TableCell>
        <TableCell>{renderText('Lausunto')}</TableCell>
        <TableCell>{renderText('Käsittelyssä')}</TableCell>
        <TableCell>
          {renderText(
            <Link as={LinkMock}>
              Saarinen Ulla
              <br />
              (123456-789A)
            </Link>
          )}
        </TableCell>
        <TableCell>{renderText('Osaamisen kehittäminen')}</TableCell>
        <TableCell>
          {renderText(
            <Time
              date={new Date('2019-06-17')}
              withYear
            />
          )}
        </TableCell>
        <TableCell>
          {renderText(
            <Time
              date={new Date('2019-11-01')}
              withYear
            />
          )}
        </TableCell>
        <TableCell verticalAlign="middle">
          <Inline gap="md">
            <Button
              size={isSmall ? 'xs' : undefined}
              variant="outline"
            >
              Siirrä
            </Button>
            <Button size={isSmall ? 'xs' : undefined}>Jatka käsittelyä</Button>
          </Inline>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Checkbox
            ariaLabel="Valitse: Yhteydenotto"
            id="yhteydenotto"
          />
        </TableCell>
        <TableCell>
          {renderText(<Link as={LinkMock}>Yhteydenotto</Link>)}
        </TableCell>
        <TableCell>{renderText('Haastattelu')}</TableCell>
        <TableCell>{renderText('Valmis käsiteltäväksi')}</TableCell>
        <TableCell>
          {renderText(
            <Link as={LinkMock}>
              Saarinen Pekka
              <br />
              (123456-789A)
            </Link>
          )}
        </TableCell>
        <TableCell>{renderText('Tuettu työllistyminen')}</TableCell>
        <TableCell>
          {renderText(
            <Time
              date={new Date('2019-06-19')}
              withYear
            />
          )}
        </TableCell>
        <TableCell>
          {renderText(
            <Time
              date={new Date('2019-11-05')}
              withYear
            />
          )}
        </TableCell>
        <TableCell verticalAlign="middle">
          <Inline gap="md">
            <Button
              size={isSmall ? 'xs' : undefined}
              variant="outline"
            >
              Siirrä
            </Button>
            <Button size={isSmall ? 'xs' : undefined}>Aloita käsittely</Button>
          </Inline>
        </TableCell>
      </TableRow>
    </TableBody>
  )
}

const TableExample1 = () => (
  <Table responsive>
    {TableHeaderExample()}
    {TableBodyExample()}
  </Table>
)

export { TableExample1 }
