import React from 'react'

import {
  Button,
  Inline,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Text
} from '@te-digi/styleguide'

const TableExample3 = () => (
  <Table
    divider="horizontal"
    responsive
    size="sm"
  >
    <TableHeader>
      <TableRow>
        <TableHeaderCell scope="col">Aihe</TableHeaderCell>
        <TableHeaderCell scope="col">Tarkenne</TableHeaderCell>
        <TableHeaderCell scope="col">Tila</TableHeaderCell>
        <TableHeaderCell scope="col">Asiakas</TableHeaderCell>
        <TableHeaderCell scope="col">Palvelutarve</TableHeaderCell>
        <TableHeaderCell
          scope="col"
          style={{ width: '1%' }}
        >
          Toiminnot
        </TableHeaderCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      <TableRow>
        <TableCell>
          <Text size="sm">Työnhaun aloitus</Text>
        </TableCell>
        <TableCell>
          <Text size="sm">Lausunto</Text>
        </TableCell>
        <TableCell>
          <Text size="sm">Käsittelyssä</Text>
        </TableCell>
        <TableCell>
          <Text size="sm">
            Saarinen Ulla
            <br />
            (123456-789A)
          </Text>
        </TableCell>
        <TableCell>
          <Text size="sm">Osaamisen kehittäminen</Text>
        </TableCell>
        <TableCell verticalAlign="middle">
          <Inline gap="md">
            <Button
              size="xs"
              variant="outline"
            >
              Siirrä
            </Button>
            <Button size="xs">Jatka käsittelyä</Button>
          </Inline>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Text size="sm">Yhteydenotto</Text>
        </TableCell>
        <TableCell>
          <Text size="sm">Haastattelu</Text>
        </TableCell>
        <TableCell>
          <Text size="sm">Valmis käsiteltäväksi</Text>
        </TableCell>
        <TableCell>
          <Text size="sm">
            Saarinen Pekka
            <br />
            (123456-789A)
          </Text>
        </TableCell>
        <TableCell>
          <Text size="sm">Tuettu työllistyminen</Text>
        </TableCell>
        <TableCell verticalAlign="middle">
          <Inline gap="md">
            <Button
              size="xs"
              variant="outline"
            >
              Siirrä
            </Button>
            <Button size="xs">Jatka käsittelyä</Button>
          </Inline>
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
)

export { TableExample3 }
