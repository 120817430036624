import React from 'react'

import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import { TableExample1 } from '../../examples/react/TableExample1'
import { TableExample2 } from '../../examples/react/TableExample2'
import { TableExample3 } from '../../examples/react/TableExample3'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Table"
    components={[
      { component: Table },
      { component: TableHeader },
      { component: TableBody },
      { component: TableRow },
      { component: TableHeaderCell },
      { component: TableCell }
    ]}
  >
    <Section>
      <Playground example={TableExample1} />
    </Section>
    <Section title="Taulukko viivoilla">
      <Playground example={TableExample2} />
    </Section>
    <Section title="Taulukko pienemmällä välityksellä ja viivoilla">
      <Playground example={TableExample3} />
    </Section>
  </Content>
)

export default Page
