import React from 'react'

import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from '@te-digi/styleguide'

const TableExample2 = () => (
  <Table
    divider="horizontal"
    responsive
  >
    <TableHeader>
      <TableRow>
        <TableHeaderCell scope="col">Aihe</TableHeaderCell>
        <TableHeaderCell scope="col">Tarkenne</TableHeaderCell>
        <TableHeaderCell scope="col">Tila</TableHeaderCell>
        <TableHeaderCell scope="col">Asiakas</TableHeaderCell>
        <TableHeaderCell scope="col">Palvelutarve</TableHeaderCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      <TableRow>
        <TableCell>Työnhaun aloitus</TableCell>
        <TableCell>Lausunto</TableCell>
        <TableCell>Käsittelyssä</TableCell>
        <TableCell>
          Saarinen Ulla
          <br />
          (123456-789A)
        </TableCell>
        <TableCell>Osaamisen kehittäminen</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Yhteydenotto</TableCell>
        <TableCell>Haastattelu</TableCell>
        <TableCell>Valmis käsiteltäväksi</TableCell>
        <TableCell>
          Saarinen Pekka
          <br />
          (123456-789A)
        </TableCell>
        <TableCell>Tuettu työllistyminen</TableCell>
      </TableRow>
    </TableBody>
  </Table>
)

export { TableExample2 }
